import axios from 'axios';

const API = 'conjuntos/modulos'

const ENDPOINTS = {
	getModulos(params){
		return axios.get(`${API}/listar`, {params});
	},

	changeValue(payload){
		return axios.post(`${API}/guardar`, payload);
	}
};

export default ENDPOINTS