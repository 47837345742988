<template>
    <section class="px-5">
        <p class="text-black f-20 f-500 mb-4">
            Módulos visibles en el proyecto
        </p>
        <template v-if="showData">
        <div class="row mx-0 align-items-center">
            <el-checkbox v-model="reservaValue" :true-label="1" :false-label="0" class="check-dark" @change="changeValue(33, reservaValue)" />
            <i class="icon-reservas f-16 text-black mx-2" />
            <div class="col-auto text-black f-18">
                Reservas
            </div>
        </div>
        <div class="row mx-0 mt-4 align-items-center">
            <el-checkbox v-model="mercarValue" :true-label="1" :false-label="0" class="check-dark" @change="changeValue(31, mercarValue)" />
            <i class="icon-mercar f-18 text-black mx-2" />
            <div class="col-auto text-black f-18">
                Mercar
            </div>
        </div>
        <div class="row mx-0 mt-4 align-items-center">
            <el-checkbox v-model="taxiValue" :true-label="1" :false-label="0" class="check-dark" @change="changeValue(32, taxiValue)" />
            <i class="icon-carro f-13 text-black mx-2" />
            <div class="col-auto text-black f-18">
                Taxi
            </div>
        </div>
        <div class="row mx-0 mt-4 align-items-center">
            <el-checkbox v-model="chatValue" :true-label="1" :false-label="0" class="check-dark" @change="changeValue(34, chatValue)" />
            <i class="icon-speech-bubble f-18 text-black mx-2" />
            <div class="col-auto text-black f-18">
                Chat
            </div>
        </div>
        <div class="row mx-0 mt-4 align-items-center">
            <el-checkbox v-model="pagosValue" :true-label="1" :false-label="0" class="check-dark" @change="changeValue(35, pagosValue)" />
            <i class="icon-pagos f-18 text-black mx-2" />
            <div class="col-auto text-black f-18">
                Pagos
            </div>
        </div>
        <div class="row mx-0 mt-4 align-items-center">
            <el-checkbox v-model="llamadosValue" :true-label="1" :false-label="0" class="check-dark" @change="changeValue(36, llamadosValue)" />
            <i class="icon-alerta_2 f-16 text-black mx-2" />
            <div class="col-auto text-black f-18">
                Llamados de atención
            </div>
        </div>
        <div class="row mx-0 mt-4 align-items-center">
            <el-checkbox v-model="pqrValue" :true-label="1" :false-label="0" class="check-dark" @change="changeValue(37, pqrValue)"  />
            <i class="icon-correo f-15 text-black mx-2" />
            <div class="col-auto text-black f-18">
                PQR
            </div>
        </div>
        <div class="row mx-0 mt-4 align-items-center">
            <el-checkbox v-model="visitasValue" :true-label="1" :false-label="0" class="check-dark" @change="changeValue(38, visitasValue)"  />
            <i class="icon-solicitud f-16 text-black mx-2" />
            <div class="col-auto text-black f-18">
                Visitantes
            </div>
        </div>
        <div class="row mx-0 mt-4 align-items-center">
            <el-checkbox v-model="administracion" :true-label="1" :false-label="0" class="check-dark" @change="changeValue(39, administracion)"  />
            <i class="icon-pagos f-18 text-black mx-2" />
            <div class="col-auto text-black f-18">
                Pagos administración
            </div>
        </div>
        </template>
        <template v-else>
            <p class="parpadea">Cargando...</p>
        </template>
    </section>
</template>

<script>
import Service from '@/services/conjuntos/modulos';
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            showData: false,
            reservaValue: 1,
            mercarValue: 1,
            taxiValue: 1,
            chatValue: 1,
            pagosValue: 1,
            llamadosValue: 1,
            pqrValue: 1,
            visitasValue: 1,
            administracion: 1,
        }
    },

    computed: {
		...mapGetters({
			conjunto: 'conjuntos/conjunto',
		}),
	},

    mounted(){
        this.getModulos();
    },

    methods: {
        async getModulos(){
            try {
                if(_.isEmpty(this.conjunto)){
                    setTimeout(() => {
                        this.getModulos();
                    },500);
                    return;
                }
                const params = {
                    idConjunto: this.conjunto.id
                };
                const {data} = await Service.getModulos(params);

                this.reservaValue = data.modulo_reserva;
                this.mercarValue = data.modulo_mercar;
                this.taxiValue = data.modulo_taxi;
                this.chatValue = data.modulo_chat;
                this.pagosValue = data.modulo_pagos;
                this.llamadosValue = data.modulo_llamados;
                this.pqrValue = data.modulo_pqr;
                this.visitasValue = data.modulo_visitas;
                this.administracion = data.modulo_administracion;

                this.showData = true;
            } catch(e){
                this.error_catch(e);
            }
        },

        async changeValue(type, value){
            try {
                if(_.isEmpty(this.conjunto)){
                    return;
                }
                const payload = {
                    tipo: type,
                    valor: value,
                    idConjunto: this.conjunto.id
                };
                const {data} = await Service.changeValue(payload);
                if(data.exito){
                    this.notificacion('Mensaje', 'Módulo actualizado', 'success');
                }
            } catch(e){
                this.error_catch(e);
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.label{
    color: #5F6D84;
    font-size: 12px;
    padding-left: 15px;
}
</style>
